import styled from "styled-components"

const SolutionsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => (props.cols ? props.cols : 4)}, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
  gap: 0 32px;
  color: var(--base070);
  align-content: stretch;

  > div {
    font-size: 40px;
    color: var(--base100);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(12, auto);
  }
`

const Solution = styled.div`
  display: contents;
  color: var(--base100);

  > h5 {
    padding: 16px 0;
  }

  > div {
    font-size: 40px;

    svg {
      stroke-width: 1.25px;
    }
  }

  svg {
    color: var(--primary100);
  }

  :not(:last-child) p {
    margin-bottom: 32px;
  }
`
/* 
const SolutionsRow = ({ props, children }) => {
  return <SolutionsRowWrapper cols={props.cols}>{children}</SolutionsRowWrapper>
} */

export { Solution, SolutionsRow }
