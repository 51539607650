import React, { useRef } from "react"
import Banner from "../components/Banner"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/Button"
import {
  Body,
  Header1,
  Header1Accent,
  Header2,
  Header3,
  Header5,
} from "../components/Typography"
import SectionHeader from "../components/SectionHeader"
import Section from "../components/Section"
import { SolutionsRow, Solution } from "../components/solutionsRow"

import { FiFilter, FiTrendingUp, FiZoomIn } from "react-icons/fi"
import MainPoint from "../components/MainPoint"
import opengraph from "../images/opengraph.png"

function DevelopmentPage({ data }) {
  const banner = useRef(null)
  return (
    <Layout pageTitle="Development" image={opengraph}>
      <Banner
        text={[
          <Header1>
            Don't leave <Header1Accent>money</Header1Accent> on the table
          </Header1>,
          <Header3>
            It’s not what goes into the pipeline that counts - it’s what comes
            out.
          </Header3>,
        ]}
        buttons={[
          <Button primary url="/demo">
            Request Demo
          </Button>,
        ]}
        ref={banner}
        image={data.banner}
      />
      <Section>
        <SectionHeader>What We Do</SectionHeader>
        <Header2 style={{ margin: "32px 0px 48px 0px" }}>
          Leverage academic research and cutting-edge technology to maximize
          your fundraising efforts
        </Header2>
        <SolutionsRow cols={3}>
          <Solution>
            <div>
              <FiFilter />
            </div>
            <Header5>Build Your Pipeline</Header5>
            <Body>
              Cost-effectively scale your development operation to identify
              qualified prospects by uncovering affinities, confirming capacity,
              and rating the philanthropic intent of each alum.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiZoomIn />
            </div>
            <Header5>Cultivate Prospects</Header5>
            <Body>
              Leverage large-scale discovery to develop personalized cultivation
              strategies that will increase willingness to give.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiTrendingUp />
            </div>
            <Header5>See the impact</Header5>
            <Body>
              Identify and measure the impact of every moving piece. Optimize
              your resources accordingly and streamline your fundraising
              efforts.
            </Body>
          </Solution>
        </SolutionsRow>
      </Section>
      <Section id="qualification">
        <MainPoint
          text={[
            <Header2>Qualification</Header2>,
            <Body>
              Automate your qualification process and spend more time with the
              right prospects. Use qualitative data to uncover why an alum
              donates.
            </Body>,
            <ul
              style={{
                display: "grid",
                gap: "inherit",
                gridAutoFlow: "inherit",
              }}
            >
              <li>
                <Body>Automatically gather meaningful qualification data</Body>
              </li>
              <li>
                <Body>Score both qualitative and quantitative data</Body>
              </li>
              <li>
                <Body>Speed up your qualification process with AI</Body>
              </li>
            </ul>,
          ]}
          image={data.qualification}
        />
      </Section>
      <Section id="cultivation">
        <MainPoint
          reversed
          text={[
            <Header2>Cultivation</Header2>,
            <Body>
              Increase your prospect's willingness to give and uncover
              actionable insights into what emotionally connects them to your
              institution.
            </Body>,
            <ul
              style={{
                display: "grid",
                gap: "inherit",
                gridAutoFlow: "inherit",
              }}
            >
              <li>
                <Body>Large-scale discovery in seconds</Body>
              </li>
              <li>
                <Body>Uncover who and what matters to your prospects</Body>
              </li>
              <li>
                <Body>
                  Learn which prospect's connections may be able to help in the
                  cultivation process
                </Body>
              </li>
            </ul>,
          ]}
          image={data.cultivation}
        />
      </Section>
      <Section id="impact-analysis">
        <MainPoint
          text={[
            <Header2>Impact analysis</Header2>,
            <Body>
              Analyze the impact each department has on your institution's
              philanthropic goals. Discover and measure what actually moves the
              fundraising needle.
            </Body>,
            <ul
              style={{
                display: "grid",
                gap: "inherit",
                gridAutoFlow: "inherit",
              }}
            >
              <li>
                <Body>Optimize your resources</Body>
              </li>
              <li>
                <Body>Directly impact fundraising</Body>
              </li>
              <li>
                <Body>
                  Leverage existing digital assets to increase engagement and
                  giving
                </Body>
              </li>
            </ul>,
          ]}
          image={data.impact}
        />
      </Section>
      <Section id="culture">
        <MainPoint
          reversed
          text={[
            <Header2>Culture of philanthropy</Header2>,
            <Body>
              Foster a culture of philanthropy at your institution by
              quantifying how each department impacts giving and how our
              engagement approach also helps them reach their goals.
            </Body>,
            <ul
              style={{
                display: "grid",
                gap: "inherit",
                gridAutoFlow: "inherit",
              }}
            >
              <li>
                <Body>
                  Combine disparate data sources to impact giving at the
                  institutional level
                </Body>
              </li>
              <li>
                <Body>
                  Help admissions, career services, student services, and other
                  departments with their initiatives
                </Body>
              </li>
            </ul>,
          ]}
          image={data.culture}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    lightImage: allFile(
      filter: { relativePath: { eq: "index-bg-light.jpeg" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, aspectRatio: 1)
        }
      }
    }
    banner: allFile(
      filter: {
        relativePath: { eq: "development/kaleb-tapp-1deQbU6DhBg-unsplash.jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    qualification: allFile(
      filter: {
        relativePath: {
          eq: "development/christina-wocintechchat-com-eF7HN40WbAQ-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    cultivation: allFile(
      filter: {
        relativePath: { eq: "development/abi-howard-CD8RhVDern0-unsplash.jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    impact: allFile(
      filter: {
        relativePath: {
          eq: "development/jason-goodman-bzqU01v-G54-unsplash.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            width: 800
            aspectRatio: 1
          )
        }
      }
    }
    culture: allFile(
      filter: {
        relativePath: {
          eq: "development/krakenimages-376KN_ISplE-unsplash-2.jpg"
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: CONSTRAINED
            aspectRatio: 1
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
  }
`

export default DevelopmentPage
